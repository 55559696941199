
require('@fortawesome/fontawesome-free/css/all.css')

import Vue from 'vue'
import Rating from '@/components/BulletRating.vue'

const soft_skills = require('@/assets/soft_skills.json')
const extra_soft_skills = require('@/assets/extra_soft_skills.json')

export default Vue.extend({
    name: 'CV',
    components: {
        Rating
    },
    data: function() {
        return {
            mounted: false,
            soft_skills: soft_skills,
            extra_soft_skills: extra_soft_skills,
            extra_info: false,
            extra_education: false,
            extra_experience: false,
            extra_extracurricular_activities: false,
            extra_skills: false,
            extra_programming_languages: false,
        }
    },
    methods: {
        print() {
            var print_content = (this.$refs['print-container'] as Element).innerHTML
            document.body.innerHTML = print_content
            window.print()
            window.location.reload()
        },
        toggleExtraContent(subject: string) {
            // @ts-ignore
            this['extra_' + subject] = !this['extra_' + subject]
            var extra_content = this.$refs['extra_' + subject] as HTMLElement
            if (extra_content.style.display === 'none') {
                extra_content.style.display = 'block'
            } else {
                extra_content.style.display = 'none'
            }
        },
    },
    mounted() {
        this.mounted = true
    },
})
