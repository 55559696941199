
require('@fortawesome/fontawesome-free/css/all.css')
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class StarRating extends Vue {
    @Prop() rating!: number
    @Prop({ default: 5 }) base!: number

    get stars() {
        var stars: Array<number> = []
        for (let j = 0; j < this.base; j++) {
            let val = this.rating - j
            if (val >= 0.75) {
                stars.push(1)
            } else if (val >= 0.25) {
                stars.push(0.5)
            } else {
                stars.push(0)
            }
        }
        return stars
    }
}
